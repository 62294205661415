import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default function PageNotFound() {
  return (
    <Container>
      Oopsie Daisie. The page you're looking for can't be found.
    </Container>
  )
}
